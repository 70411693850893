import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AlloysPanel,
  ServicesPanel,
  TitlePanel,
  AboutPanel,
  ContactPanel,
} from "../Panels";

const Main = ({ setSnackbar }) => {
  let defaultForm = {
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  };

  const [contactDetails, setContactDetails] = useState(defaultForm);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = ({ target }) => {
    setContactDetails({ ...contactDetails, [target.name]: target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSaving(true);

    const requestMetadata = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactDetails),
    };

    fetch("/services/mailer", requestMetadata).then(() => {
      setIsSaving(false);
      setSnackbar({ message: "Information request sent" });
      setContactDetails(defaultForm);
    });
  };

  return (
    <>
      <div className="site-inner">
        <div className="content-sidebar-wrap">
          <main className="content">
            <TitlePanel />
            <AlloysPanel />
            <ServicesPanel />
            <AboutPanel />
            <ContactPanel
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSaving={isSaving}
              contactDetails={contactDetails}
            />
          </main>
        </div>
      </div>
    </>
  );
};

Main.propTypes = {
  setSnackbar: PropTypes.func.isRequired,
};

export default Main;
