import React from "react";
import PropTypes from "prop-types";
import Input from "../reusable/Input";

const ContactPanel = ({
  handleChange,
  handleSubmit,
  isSaving,
  contactDetails,
}) => {
  return (
    <>
      <div className="row-05" id="Contact">
        <div className="display-table">
          <div className="display-table-row">
            <div className="display-table-cell left-cell">
              <h2>Contact</h2>
              <p>
                Need a quote or have a question? Looking for a specialty metal
                or exotic material? Give us a call or send us a message through
                the form below.
              </p>
              <div className="gf_browser_chrome gform_wrapper">
                <div id="gf_1" className="gform_anchor" tabIndex="-1"></div>
                <form
                  method="post"
                  encType="multipart/form-data"
                  id="contact-form"
                  onSubmit={handleSubmit}
                >
                  <div className="gform_body">
                    <ul className="gform_fields top_label form_sublabel_below description_below">
                      <li
                        id="field_1_1"
                        className="gfield no-label gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                      >
                        <label className="gfield_label" htmlFor="name">
                          Name<span className="gfield_required">*</span>
                        </label>
                        <div className="ginput_container ginput_container_text">
                          <Input
                            id="name"
                            name="name"
                            className="large"
                            value={contactDetails.name}
                            placeholder="Name"
                            onChange={handleChange}
                          />
                        </div>
                      </li>
                      <li
                        id="field_1_2"
                        className="gfield no-label gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                      >
                        <label className="gfield_label" htmlFor="email">
                          Email
                        </label>
                        <div className="ginput_container ginput_container_email">
                          <Input
                            id="email"
                            name="email"
                            className="large"
                            value={contactDetails.email}
                            placeholder="Email"
                            onChange={handleChange}
                          />
                        </div>
                      </li>
                      <li
                        id="field_1_3"
                        className="gfield no-label field_sublabel_below field_description_below gfield_visibility_visible"
                      >
                        <label className="gfield_label" htmlFor="phone">
                          Phone
                        </label>
                        <div className="ginput_container ginput_container_phone">
                          <Input
                            id="phone"
                            name="phone"
                            className="large"
                            value={contactDetails.phone}
                            placeholder="Phone"
                            onChange={handleChange}
                          />
                        </div>
                      </li>
                      <li
                        id="field_1_5"
                        className="gfield no-label field_sublabel_below field_description_below gfield_visibility_visible"
                      >
                        <label className="gfield_label" htmlFor="company">
                          Company
                        </label>
                        <div className="ginput_container ginput_container_text">
                          <Input
                            id="company"
                            name="company"
                            className="large"
                            value={contactDetails.company}
                            placeholder="Company"
                            onChange={handleChange}
                          />
                        </div>
                      </li>
                      <li
                        id="field_1_4"
                        className="gfield no-label gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                      >
                        <label className="gfield_label" htmlFor="message">
                          Message
                          <span className="gfield_required">*</span>
                        </label>
                        <div className="ginput_container ginput_container_textarea">
                          <textarea
                            name="message"
                            id="message"
                            className="textarea medium"
                            placeholder="Message"
                            aria-required="true"
                            aria-invalid="false"
                            rows="10"
                            cols="50"
                            value={contactDetails.message}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="gform_footer top_label">
                    <input
                      type="submit"
                      id="contact-send"
                      className="gform_button button"
                      value="Send"
                      disabled={isSaving ? "disabled" : ""}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              className="display-table-cell right-cell"
              id="contact-cell"
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

ContactPanel.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  contactDetails: PropTypes.object.isRequired,
};

export default ContactPanel;
