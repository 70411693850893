import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const ServicesPanel = () => {
  return (
    <>
      <div className="row-03" id="Services">
        <div className="display-table">
          <div className="display-table-row">
            <div className="display-table-cell left-cell">
              <h2>Value-Added Services</h2>
              <p>
                Looking for a one-stop shop with exceptional service? You’ve
                come to the right place. We provide the following services,
                delivering the finest results in the industry.{" "}
                <AnchorLink href="#Contact">
                  Contact us today to find out more.
                </AnchorLink>
              </p>
              <ul>
                <li>Sawing (Up to 22” OD)</li>
                <li>Heat Treating</li>
                <li>Centerless Grinding</li>
                <li>Machining</li>
                <li>Supply Chain Management</li>
                <li>Various Testing Capabilities</li>
              </ul>
              <ul>
                <li>Gun Drilling</li>
                <li>Honing</li>
                <li>Boring</li>
                <li>Turning</li>
                <li>Trepanning</li>
                <li>Customer JIT Stocking Programs</li>
              </ul>
            </div>
            <div className="display-table-cell right-cell services"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPanel;
