import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const TitlePanel = () => {
  return (
    <>
      <div className="hero" id="Home">
        <div className="hero-video-container">
          <video muted autoPlay loop>
            <source
              src="/images/BG_Video_Update_2021_V3.mp4"
              type="video/mp4"
            />
          </video>
          <div className="static-image"></div>
        </div>
        <div className="hero-overlay-container">
          <div className="hero-table">
            <div className="hero-table-row">
              <div className="hero-table-cell">
                <h1 className="hero-title">
                  <strong>A</strong>dvancing
                  <br /> <strong>C</strong>ustomer
                  <br /> <strong>E</strong>xpectations
                  <br />
                </h1>
                <div className="hero-text">
                  <p>
                    We provide the highest quality specialty metals in the
                    industry, but our main focus is on customer service. We have
                    identified a need within the metals industry for a supply
                    chain and distribution partner that makes their customer’s
                    problems, their own. That is exactly what we do at Ace
                    Alloys through responsiveness, creativity, flexibility and
                    attention to detail. Do you have an issue? Let’s resolve it
                    together!
                  </p>
                  <p>
                    <AnchorLink className="button" href="#Alloys">
                      View Alloys
                    </AnchorLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TitlePanel;
