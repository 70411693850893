import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const AlloysPanel = () => {
  return (
    <>
      <div className="row-02" id="Alloys">
        <div className="wrap">
          <h2>Alloys</h2>
          <p>
            Our inventory includes a unique selection of items, ranging from
            Carbon Steel to High Nickel Alloys, and most items in-between. Our
            large array of product offerings aids us in servicing all aspects of
            the Oil &amp; Gas, Aerospace, Marine, Medical, Chemical and Food
            Processing Industries. Looking for something specific?{" "}
            <AnchorLink href="#Contact">Let us know.</AnchorLink>
          </p>
          <div className="full-width">
            <div className="one-sixth first">
              <p>
                <strong>Carbon Steel</strong>
                <br />
                1018
                <br />
                1045
                <br />
                1045 TG&amp;P
              </p>
            </div>
            <div className="one-sixth">
              <p>
                <strong>Alloy Steel</strong>
                <br />
                4140
                <br />
                4340
                <br />
                8620
                <br />
                9310
              </p>
            </div>
            <div className="one-sixth">
              <p>
                <strong>Copper / Bronze / Brass</strong>
                <br />
                C360
                <br />
                C464
                <br />
                C932
                <br />
                C954
                <br />
                C630
                <br />
                C172
                <br />
                C729 ToughMet
              </p>
            </div>
            <div className="one-sixth">
              <p>
                <strong>Stainless Steel</strong>
                <br />
                302
                <br />
                303
                <br />
                304/304L
                <br />
                316/316L
                <br />
                321
                <br />
                410 Annealed
                <br />
                410 QDT
                <br />
                416
                <br />
                420
                <br />
                2205
                <br />
                2507
                <br />
                15-5 VAR
                <br />
                17-4
              </p>
            </div>
            <div className="one-sixth">
              <p>
                <strong>Nickel Alloy</strong>
                <br />
                200
                <br />
                400
                <br />
                R405
                <br />
                600
                <br />
                625
                <br />
                825
                <br />
                925
                <br />
                C276
                <br />
                K500
                <br />
                718
                <br />
                MP35N
              </p>
            </div>
            <div className="one-sixth">
              <p>
                <strong>Pump Shaft Quality (PSQ)</strong>
                <br />
                Nitronic 50
                <br />
                K500
                <br />
                625
                <br />
                718
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlloysPanel;
