import React, { useState } from "react";
import Menu from "./Menu";
import Main from "./Main";
import { Snackbar } from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const App = () => {
  const [snackbar, setSnackbar] = useState(null);

  const closeSnackbar = () => {
    setSnackbar(null);
  };

  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {snackbar && (
          <Snackbar
            message={snackbar.message}
            open
            autoHideDuration={3000}
            onClose={closeSnackbar}
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            transitionDuration={500}
          />
        )}
        <Menu />
        <Main setSnackbar={setSnackbar} />
      </ThemeProvider>
    </StyledEngineProvider>
  </>;
};

export default App;
