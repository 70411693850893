import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import HamburgerMenu from "react-hamburger-menu";
import "./menu.css";

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleMenuClick = (menu) => {
    if (menuOpen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  };

  const handleAnchorClick = () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.getElementById("site-header").classList.add("activated");
    } else {
      document.getElementById("site-header").classList.remove("activated");
    }
  }, [menuOpen]);

  return (
    <>
      <header className="site-header" id="site-header">
        <div className="wrap">
          <div className="site-header-container">
            <div className="site-header-container-content">
              <div className="title-area">
                <p className="site-title">
                  <a href="https://acealloysllc.com/">Ace Alloys LLC</a>
                </p>
              </div>
              <div className="widget-area header-widget-area">
                <section id="nav_menu-2" className="widget widget_nav_menu">
                  <div className="widget-wrap">
                    <button
                      className="responsive-menu-toggle"
                      id="responsive-menu-toggle"
                      aria-pressed="false"
                    >
                      <div className="responsive-menu-icon hamburger hamburger--slider">
                        <HamburgerMenu
                          id="mobile-menu"
                          isOpen={menuOpen}
                          menuClicked={handleMenuClick}
                          width={30}
                          height={15}
                          strokeWidth={2}
                          rotate={0}
                          color="#078"
                          borderRadius={0}
                          animationDuration={0.5}
                        />
                      </div>
                      <div className="responsive-menu-text"></div>
                    </button>
                    <nav className="nav-header">
                      <ul id="menu-main-menu" className="menu genesis-nav-menu">
                        <li
                          id="menu-item-36"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-36"
                        >
                          <AnchorLink href="#Home" onClick={handleAnchorClick}>
                            Home
                          </AnchorLink>
                        </li>
                        <li
                          id="menu-item-37"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-37"
                        >
                          <AnchorLink
                            href="#Alloys"
                            onClick={handleAnchorClick}
                          >
                            Alloys
                          </AnchorLink>
                        </li>
                        <li
                          id="menu-item-38"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-38"
                        >
                          <AnchorLink
                            href="#Services"
                            onClick={handleAnchorClick}
                          >
                            Services
                          </AnchorLink>
                        </li>
                        <li
                          id="menu-item-39"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-39"
                        >
                          <AnchorLink href="#About" onClick={handleAnchorClick}>
                            About
                          </AnchorLink>
                        </li>
                        <li
                          id="menu-item-40"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-40"
                        >
                          <AnchorLink
                            href="#Contact"
                            onClick={handleAnchorClick}
                          >
                            Contact
                          </AnchorLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </section>
                <section id="text-2" className="widget widget_text">
                  <div className="widget-wrap">
                    <div className="textwidget">
                      <p>
                        <a href="/documents/Ace-Alloys-ISO-9001-Certificate-2022.pdf">
                          <br />
                          <picture className="alignnone size-medium wp-image-87">
                            <source
                              type="image/webp"
                              srcSet="/images/iso-logo-e1571192167297.webp"
                            />
                            <source
                              type="image/png"
                              srcSet="/images/iso-logo-e1571192167297.png"
                            />
                            <img
                              loading="lazy"
                              className="alignnone size-medium wp-image-87"
                              src="/images/iso-logo-e1571192167297.png"
                              alt="ISO 9001:2020 Certification"
                              width="100"
                              height="85"
                            />
                          </picture>
                          <br />
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.google.com/maps?client=safari&amp;rls=en&amp;q=Ace+Alloys+LLC+Catoosa,+OK+74015&amp;biw=942&amp;bih=789&amp;um=1&amp;ie=UTF-8&amp;sa=X&amp;ved=0ahUKEwidx9__nLDjAhUUrZ4KHRByCQsQ_AUIECgB"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          19801 E Admiral Place
                          <br />
                          Suite C<br />
                          Catoosa, OK 74015
                        </a>
                      </p>
                      <p>
                        <a href="tel:+19187394311">918-739-4311</a>
                      </p>
                      <div className="credits">
                        &copy;&nbsp;{currentYear} Ace Alloys LLC
                        <br />
                        Site by{" "}
                        <a
                          href="https://byerscreative.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Byers Creative
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Menu;
