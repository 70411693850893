import React from "react";

const AboutPanel = () => {
  return (
    <>
      <div className="row-04" id="About">
        <div className="wrap">
          <h2>About</h2>
          <p>
            We are committed to advancing customer expectations. This means
            providing exceptional customer service, on-time delivery, a
            knowledgeable staff, and top-of-the-line quality control.
          </p>
          <div className="full-width">
            <div className="staff-member">
              <picture>
                <source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Jim_MG_0162.webp"
                />
                <source
                  type="image/jpeg"
                  srcSet="/images/190524-Ace-Alloys-Jim_MG_0162.jpg"
                />
                <img
                  alt="Jim Simmons"
                  src="/images/190524-Ace-Alloys-Jim_MG_0162.jpg"
                />
              </picture>
              <div className="staff-name">Jim Simmons</div>
              <div className="staff-title">Partner</div>
              <div className="staff-phone">
                <a href="tel:+1918-739-4268">918-739-4268</a>
              </div>
            </div>
            <div className="staff-member">
              <picture>
                <source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Brandon_MG_0082.webp"
                />
                <source
                  type="image/jpeg"
                  srcSet="/images/190524-Ace-Alloys-Brandon_MG_0082.jpg"
                />
                <img
                  alt="Brandon Gerhart"
                  src="/images/190524-Ace-Alloys-Brandon_MG_0082.jpg"
                />
              </picture>
              <div className="staff-name">Brandon Gerhart</div>
              <div className="staff-title">Partner</div>
              <div className="staff-phone">
                <a href="tel:+1918-739-4123">918-739-4123</a>
              </div>
            </div>
            <div className="staff-member">
              <picture>
                <source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Pete_MG_0042.webp"
                />
                <source
                  type="image/jpeg"
                  srcSet="/images/190524-Ace-Alloys-Pete_MG_0042.jpg"
                />
                <img
                  alt="Pete Hooper"
                  src="/images/190524-Ace-Alloys-Pete_MG_0042.jpg"
                />
              </picture>
              <div className="staff-name">Pete Hooper</div>
              <div className="staff-title">Sales</div>
              <div className="staff-phone">
                <a href="tel:+1918-739-4505">918-739-4505</a>
              </div>
            </div>
            <div className="staff-member">
              <picture>
                <source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Mike_MG_0100.webp"
                />
                <source
                  type="image/jpeg"
                  srcSet="/images/190524-Ace-Alloys-Mike_MG_0100.jpg"
                />
                <img
                  alt="Mike Hooper"
                  src="/images/190524-Ace-Alloys-Mike_MG_0100.jpg"
                />
              </picture>
              <div className="staff-name">Mike Hooper</div>
              <div className="staff-title">Sales</div>
              <div className="staff-phone">
                <a href="tel:+1918-739-4035">918-739-4035</a>
              </div>
            </div>
            <div className="staff-member">
              <picture>
                <source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Kelsie_MG_0122.webp"
                />
                <source
                  type="image/jpeg"
                  srcSet="/images/190524-Ace-Alloys-Kelsie_MG_0122.jpg"
                />
                <img
                  alt="Kelsie Barlow"
                  src="/images/190524-Ace-Alloys-Kelsie_MG_0122.jpg"
                />
              </picture>
              <div className="staff-name">Kelsie Barlow</div>
              <div className="staff-title">Office Manager</div>
              <div className="staff-phone">
                <a href="tel:+1918-739-4063">918-739-4063</a>
              </div>
            </div>
            <div className="staff-member">
              <picture>
                <source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Eric_MG_0180.webp"
                />
                <source
                  type="image/jpeg"
                  srcSet="/images/190524-Ace-Alloys-Eric_MG_0180.jpg"
                />
                <img
                  alt="Eric Norton"
                  src="/images/190524-Ace-Alloys-Eric_MG_0180.jpg"
                />
              </picture>
              <div className="staff-name">Eric Norton</div>
              <div className="staff-title">Warehouse Manager</div>
              <div className="staff-phone">
                <a href="tel:+1918-739-4516">918-739-4516</a>
              </div>
            </div>
            <div className="staff-member">
              <picture>
                <source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Jason_MG_0078.webp"
                />
                <source
                  type="image/jpeg"
                  srcSet="/images/190524-Ace-Alloys-Jason_MG_0078.jpg"
                />
                <img
                  alt="Jason Simoneaux"
                  src="/images/190524-Ace-Alloys-Jason_MG_0078.jpg"
                />
              </picture>
              <div className="staff-name">Jason Simoneaux</div>
              <div className="staff-title">Warehouse Specialist</div>
            </div>
            <div className="staff-member">
              <picture>
                {/*<source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Jason_MG_0078.webp"
                />*/}
                <source
                  type="image/jpeg"
                  srcSet="/images/Art_MG_6745_web.jpg"
                />
                <img alt="Art Wallace" src="/images/Art_MG_6745_web.jpg" />
              </picture>
              <div className="staff-name">Art Wallace</div>
              <div className="staff-title">Warehouse Specialist</div>
            </div>
            <div className="staff-member">
              <picture>
                {/*<source
                  type="image/webp"
                  srcSet="/images/190524-Ace-Alloys-Jason_MG_0078.webp"
                />*/}
                <source
                  type="image/jpeg"
                  srcSet="/images/Daniel_MG_6728_web.jpg"
                />
                <img alt="Daniel Cooper" src="/images/Daniel_MG_6728_web.jpg" />
              </picture>
              <div className="staff-name">Daniel Cooper</div>
              <div className="staff-title">Warehouse Specialist</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPanel;
