import React from "react";
import PropTypes from "prop-types";

const Input = ({ id, type, name, value, placeholder, onChange, error }) => {
  return (
    <div>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

Input.propTypes = {
  /** HTML ID */
  id: PropTypes.string.isRequired,

  /** Input name */
  name: PropTypes.string.isRequired,

  /** Input value */
  value: PropTypes.string.isRequired,

  /** Placeholder **/
  placeholder: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
  /**
   ### One of
   - text
   - number
   - date
   - email
   - password
   - tel
   */

  type: PropTypes.oneOf(["text", "number", "date", "email", "password", "tel"]),

  /** Error message to show */
  error: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
};

export default Input;
